(function ($) {
	'use strict';
	let mm = window.matchMedia('(min-width: 768px)');
	$('.film-button').on('click', function () {
		$('.film-button').removeClass('active');
		$(this).addClass('active');
		if (mm.matches) {
			let desktopUrl = $(this).children('a').attr('href');
			document.location = desktopUrl;
		}
	})

	$('#carouselFilmExplore').on('slid.bs.carousel', function() {
		var indicatorsAct = $(".carousel-indicators button.active").data('bs-slide-to');
		$(".film-button-container .film-button").removeClass("active");
		$(".film-button-container").find("[data-bs-slide-to='" + indicatorsAct + "']").addClass("active");
	});
	
}(jQuery))
